import working from './assets/undraw_working_remotely.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='main-h'>Cookifood</div>
        <div className='main-p'>We're comming soon! We're working hard to give you the best experience.</div>
        <img src={working} alt="working" className='img-working' width={400} />
      </header>
    </div>
  );
}

export default App;
